import React from 'react';

class Yandex extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			
		};
	}
	
	appendYaMetrix() {
		const yaScript = document.createElement('script');
		yaScript.setAttribute('type', 'text/javascript');
		yaScript.innerHTML = `(function(w, d, n, s, t) {
				w[n] = w[n] || [];
				w[n].push(function() {
					Ya.Context.AdvManager.render({
						blockId: "R-A-406377-1",
						renderTo: "yandex_rtb_R-A-406377-1",
						async: true
					});
				});
				t = d.getElementsByTagName("script")[0];
				s = d.createElement("script");
				s.type = "text/javascript";
				s.src = "//an.yandex.ru/system/context.js";
				s.async = true;
				t.parentNode.insertBefore(s, t);
			})(this, this.document, "yandexContextAsyncCallbacks");`
		document.body.appendChild(yaScript);
	}
	
	componentDidMount() {
		this.appendYaMetrix();
	}
	
	render() {
		return(
			<div id="yandex_rtb_R-A-406377-1"></div>
		);
	}
}
export default Yandex;