import React from 'react';

class Comments extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			
		};
	}
	
	render() {
		//список комментариев
		let comments = this.props.comments.map((data) => {
			let name;
			if(data.use_nickname === '1') name = data.nickname;
			else name = data.name;
			
			//расчет времени
			let ago = data.now - data.time;
			if(ago > 604800) ago = Math.floor(ago/604800) + "н";
			else if(ago > 86400) ago = Math.floor(ago/86400) + "д";
			else if(ago > 3600) ago = Math.floor(ago/3600) + "ч";
			else if(ago > 60) ago = Math.floor(ago/60) + "м";
			else ago = ago + "с";
			
			return <div className="comment" key={data.id}>
				<div className="comment-avatar">
					<img src={data.photo} alt="" />
				</div>
				<div className="comment-content">
					<div className="comment-header">
						<div className="comment-name">{name}</div>
						<span className="time-ago">{ago} назад</span>
					</div>
					<p className="comment-text">{data.text}</p>
				</div>
			</div>
		});
		return (
			<div>{comments}</div>
		);
	}
}
export default Comments;