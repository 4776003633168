import React from 'react';
import { Redirect } from "react-router-dom";

class Memes extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			redirect: false,
			ads: this.props.ads
		};
	}
	
	//редиректим на страницу мема
	toMem = (value) => {
		this.props.editIdMem(value);
		this.setState({redirect: true});
	}
	
	render() {
		if(this.state.redirect) return <Redirect to="/" />
		
		let memes = this.props.memes.map((data) => {
			let status;
			if(this.props.my === 'my'){
				switch(data.status){
					case '0':
						status = <div className="mem-status">На модерации</div>;
					break;
					case '1':
						status = <div className="mem-status">Одобрен</div>;
					break;
					case '2':
						status = <div className="mem-status">Отклонен</div>;
					break;
					default: status = ''; break;
				}
			}
			let link; //определяем ссылку картинки. внешняя или внутренняя
			if(data.outside === '1') link = data.outside_link;
			else link = this.props.urlImages + data.file;
			
			return <div className="mem" onClick={() => this.toMem(data.id)} key={data.id}>
				<div className="inside-mem">
					<div className="image-mem" style={{backgroundImage: "linear-gradient(0deg, rgba(39, 36, 55, 0.5), rgba(39, 36, 55, 0.5)), url(" + link + ")"}}>{status}</div>
					<div className="like-block">
						<div className="comments">{data.comments}</div>
						<div className="likes">{data.likes}</div>
					</div>
				</div>
			</div>
		});
		
		let ads;
		
		if(this.state.ads) ads = <div className="mem">
				<a href="https://cityp.mobi" className="ads-link"><div className="inside-mem ads">
				</div></a>
			</div>;	
		
		return (
			<div className="memes-container">
				{/*ads*/}
				{memes}
			</div>
		);
	}
}
export default Memes;