import React from 'react';
import { Redirect } from "react-router-dom";
import connect from '@vkontakte/vkui-connect';
import axios from 'axios';
import Header from './Header';
import Memes from './Memes';
import Profile from './Profile';

class Catalog extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			day: 'active',
			best: 'active-caregory',
			timePanel: true,
			sortMemes: this.props.sortMemes,
			category: this.props.category,
			title: 'Лучшие мемасы',
			profile: false,
			filename: '',
			stageUploadMem: 1,
			uploadResult: null,
			loadMemesIteration: 1,
			checkScroll: true,
			checkFirstLoad: true
		};
	}

	componentDidMount() {
		connect.subscribe((e) => {
			switch (e.detail.type){
				case 'VKWebAppJoinGroupResult':
					this.counter('yes');
				break;
				case 'VKWebAppJoinGroupFailed':
					this.counter('no');
				break;
				default: 
			}
		});
		
		var self = this;
		axios.get(this.props.url + '/backend/checkUser.php', {
			params: {
				vk_id: this.props.user.id,
				photo: this.props.user.photo_100,
				name: this.props.user.first_name,
				lastname: this.props.user.last_name,
				timezone: this.props.user.timezone,
				search: this.props.search
			}
		}).then(function (response) {
			self.setState({
				newbie: response.data.newbie,
				subscribed: response.data.subscribed,
				subscribe_asked: response.data.subscribe_asked
			});
			self.checkSubscribe();
		});
		this.sort(this.state.sortMemes);
		this.sortFooter(this.state.category);
		
		//событие скролла
		window.addEventListener('scroll', (e) => this.moreMemesFunction());
		
	}
	
	//функция определения нижнего края списка мемов
	moreMemesFunction = () => {
		//берем элемент
		var more = document.querySelector('#more-memes');
		//если блок существует
		if(more != null){
			// Все позиции элемента
			var targetPosition = {
				top: window.pageYOffset + more.getBoundingClientRect().top,
				left: window.pageXOffset + more.getBoundingClientRect().left,
				right: window.pageXOffset + more.getBoundingClientRect().right,
				bottom: window.pageYOffset + more.getBoundingClientRect().bottom
			},
			// Получаем позиции окна
			windowPosition = {
				top: window.pageYOffset,
				left: window.pageXOffset,
				right: window.pageXOffset + document.documentElement.clientWidth,
				bottom: window.pageYOffset + document.documentElement.clientHeight
			};
			if(targetPosition.bottom > windowPosition.top && targetPosition.top < windowPosition.bottom && targetPosition.right > windowPosition.left && targetPosition.left < windowPosition.right) {
				this.loadMoreMemes();
			}
		}
	}
	
	//подшружаем мемы
	loadMoreMemes = () => {
		var self = this;
		if(this.state.moreMemes && this.state.checkScroll){
			self.setState({loadMore: true, checkScroll: false});
			var iteration = Number(this.state.loadMemesIteration) + 1;
			axios.get(this.props.url + '/backend/loadMoreMemes.php', {
				params: {
					vk_id: this.props.user.id,
					sort_memes: this.state.sortMemes,
					category: this.state.category,
					iteration: this.state.loadMemesIteration,
					search: this.props.search
				}
			}).then(function (response) {
				if(self.state.memes) self.setState({memes: self.state.memes.concat(response.data.memes)});
				self.setState({
					loadMemesIteration: iteration,
					loadMore: false,
					moreMemes: response.data.more,
					checkScroll: true
				});
				
			});
		}
	}
	
	//проверка на подписку на группу
	checkSubscribe = () => {
		if (this.state.subscribed === '0' && (this.state.subscribe_asked === '0' || this.state.subscribe_asked === '2' || this.state.subscribe_asked === '10')) {
			setTimeout(function() {connect.send("VKWebAppJoinGroup", {
				"group_id": 177029891
			})}, 500);
		}
		else this.counter('no');
	}
	
	//счетчик посещений
	counter = (value) => {
		axios.get(this.props.url + '/backend/subscribe.php', {
			params: {
				vk_id: this.props.user.id,
				to_group: value,
				search: this.props.search
			}
		});
	}
	
	//загрузка мемов
	loadMemes = () => {
		var self = this;
		axios.get(this.props.url + '/backend/loadMemes.php', {
			params: {
				vk_id: this.props.user.id,
				sort_memes: this.state.sortMemes,
				category: this.state.category,
				search: this.props.search
			}
		}).then(function (response) {
			self.setState({
				memes: response.data.memes,
				moreMemes: response.data.more
			});
		});
	}
	
	//функция сортировки мемов по времени
	sort = (value) => {
		if(value !== this.state.sortMemes || this.state.checkFirstLoad){
			this.cancelSend();
			var self = this;
			this.setState({ memes: false, day: '', week: '', month: '', allMemes: '', uploadResult: '', checkScroll: true, loadMemesIteration: 1 });
			switch(value){
				case 'day':
					this.setState({
						day: 'active',
						sortMemes: 'day',
						title: 'Лучшие мемасы',
						profile: false
					});
				break;
				case 'week':
					this.setState({
						week: 'active',
						sortMemes: 'week',
						title: 'Лучшие мемасы',
						profile: false
					});
				break;
				case 'month':
					this.setState({
						month: 'active',
						sortMemes: 'month',
						title: 'Лучшие мемасы',
						profile: false
					});
				break;
				case 'allMemes':
				default:
					this.setState({
						allMemes: 'active',
						sortMemes: 'allMemes',
						title: 'Лучшие мемасы',
						profile: false
					}); 
				break;
				
			}
			//подгружаем мемы 
			setTimeout(function(){
				self.props.sortEdit(self.state.sortMemes);
				self.loadMemes();
			}, 100);
		}
	}
	
	//функция сортировки мемов
	sortFooter = (value) => {
		
		if(value !== this.state.category || this.state.checkFirstLoad){
			this.cancelSend();
			var self = this;
			this.setState({ memes: false, best: '', newMemes: '', loved: '', my: '', uploadResult: '', checkScroll: true, loadMemesIteration: 1, checkFirstLoad: false });
			switch(value){
				case 'newMemes':
					this.setState({
						newMemes: 'active-caregory',
						timePanel: false,
						category: 'newMemes',
						title: 'Новые',
						profile: false
					});
				break;
				case 'loved':
					this.setState({
						loved: 'active-caregory',
						timePanel: false,
						category: 'loved',
						title: 'Любимые',
						profile: false
					});
				break;
				case 'my':
					this.setState({
						my: 'active-caregory',
						timePanel: false,
						category: 'my',
						title: 'Мои мемасы',
						profile: true
					});
				break;
				case 'best':
				default:
					this.setState({
						best: 'active-caregory',
						timePanel: true,
						category: 'best',
						title: 'Лучшие мемасы',
						profile: false
					});
				break;
			}
			//подгружаем мемы
			setTimeout(function(){
				self.props.categoryEdit(self.state.category);
				self.loadMemes();
			}, 100);
		}
	}
	
	//клик на нарисованный <input type="file" />
	clickToFile = () => {
		var file = document.querySelector('#input-file');
		file.click();
	}
	
	//функция выбора файла
	handleselectedFile = event => {
		//берем имя файла
		var filename = document.getElementById("input-file").files;
		filename = filename[0]['name'];
		if(filename.length > 15){
			filename = filename.substring(0, 15) + '...';
		}
		this.setState({
			selectedFile: event.target.files[0],
			filename: filename,
			stageUploadMem: 2
		})
	}
	
	//отмена отправки мема и сброс файла
	cancelSend = () => {
		this.setState({
			stageUploadMem: 1,
			selectedFile: false,
			filename: ''
		});
	}
	
	//отправка мема на сервер
	sendNewMem = () => {
		this.setState({stageUploadMem: 4});
		var self = this;
		const data = new FormData();
		data.append('file', this.state.selectedFile, this.state.selectedFile.name);
		data.append('vk_id', this.props.user.id);
		data.append('search', this.props.search);
		axios.post(this.props.url + '/backend/uploadMem.php', data, {
			onUploadProgress: ProgressEvent => {
				this.setState({
					fileLoaded: (ProgressEvent.loaded / ProgressEvent.total*100),
				})
			},
		}).then(function (response) {
			self.setState({
				uploadResult: response.data,
				stageUploadMem: 3,
				memes: false
			});
			self.loadMemes();
		});
	}
	
	render() {
		let memes, timePanel, profile, loadMemesBlock, resultUpload, moreMemes, loadMore;
		
		//проверка на новичка
		if(this.state.newbie === '1') return <Redirect to="/intro" />;
		
		//невидимый блок для подгрузки мемов (если в зоне видимости)
		if(this.state.moreMemes) moreMemes = <div id="more-memes"></div>;
		
		//Спинер загрузки
		if(this.state.loadMore) loadMore = <div className="more-memes"></div>;
		
		//панель переключения сортировки
		if(this.state.timePanel) timePanel = <div className="switch-time">
					<ul>
						<li className={this.state.day} onClick={() => this.sort('day')}>За день</li>
						<li className={this.state.week} onClick={() => this.sort('week')}>За неделю</li>
						<li className={this.state.month} onClick={() => this.sort('month')}>За месяц</li>
						<li className={this.state.allMemes} onClick={() => this.sort('allMemes')}>Все</li>
					</ul>
				</div>;
		
		//загружены или не загружены мемы
		if(!this.state.memes) memes = <div className="load-mems"></div>;
		else memes = <div>
						<Memes
							editIdMem={this.props.editIdMem}
							memes={this.state.memes}
							my={this.state.category}
							urlImages={this.props.urlImages}
							ads={true}
						/>
						{loadMore}
						{moreMemes}
					</div>;
					
		//блок профайла
		if(this.state.profile) profile = <Profile
											url={this.props.url}
											id={this.props.user.id}
											search={this.props.search}
										/>;
		
		//блок загрузки мема
		if(this.state.category === 'my' && this.state.memes){
			//определяем блок по стадии
			switch(this.state.stageUploadMem){
				case 2: 
					loadMemesBlock = <div className="load-memes-block">
						<div className="upload-your-mem" style={{paddingTop:"5px"}}>Картинка: {this.state.filename}</div>
						<div className="upload-buttons">
							<div className="send-mem" onClick={this.sendNewMem}>Постить</div>
							<div className="cancel-send" onClick={this.cancelSend}>Отмена</div>
						</div>
					</div>;
				break;
				case 3:
					if(this.state.uploadResult === 'success') resultUpload = <div className="success-upload">Спасибо за добавленный мем! После модерации администратора, он будет опубликован</div>;
					else resultUpload = <div className="error-upload">Произошла неизвесная ошибка, попробуйте позже...</div>
					loadMemesBlock = <div className="load-memes-block" onClick={this.cancelSend}>
						{resultUpload}
					</div>;
				break;
				case 4:
					loadMemesBlock = <div className="load-memes-block loading-mem"></div>;
				break;
				case 1:
				default:
					loadMemesBlock = <div className="load-memes-block" onClick={this.clickToFile}>
						<div className="add-mem"></div>
						<div className="upload-your-mem">Загрузить свой мем</div>
					</div>;
				break;
			}
		}
		
		return (
			<div>
				<Header title={this.state.title}/> {this.state.newbie}
				{timePanel}
				{profile}
				{loadMemesBlock}
				{memes}
				<div className="footer-space"></div>
				<div className="footer">
					<div className="switch-category">
						<ul>
							<li className={this.state.best} onClick={() => this.sortFooter('best')}>Лучшие</li>
							<li className={this.state.newMemes} onClick={() => this.sortFooter('newMemes')}>Новые</li>
							<li className={this.state.loved} onClick={() => this.sortFooter('loved')}>Любимые</li>
							<li className={this.state.my} onClick={() => this.sortFooter('my')}>Мои</li>
						</ul>
					</div>
				</div>
				<input type="file" style={{display: 'none'}} onChange={this.handleselectedFile} id="input-file" accept="image/*" />
			</div>
		);
	}
}
export default Catalog;