import React from 'react';
import { Redirect } from "react-router-dom";
import axios from 'axios';
import Header from './Header';
import Comments from './Comments';
import connect from '@vkontakte/vkui-connect';
import Yandex from './Yandex';

class Mem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			category: this.props.category,
			sortMemes: this.props.sortMemes,
			idMem: this.props.idMem,
			loadedMem: false,
			photo: null,
			likes: 0,
			dislikes: 0,
			countComments: 0,
			activeLike: false,
			activeDislike: false,
			loadComments: false,
			comments: [],
			newComment: '',
			token: false,
			uploadServer: false,
			wallButton: false
		};
	}
	
	componentDidMount(){
		connect.subscribe((e) => {
			switch (e.detail.type) {
				case 'VKWebAppShowWallPostBoxResult':
					this.setState({wallButton: true});
				break;
				default:
			}
		});
		
		//подгружаем мем по id
		var self = this;
		axios.get(this.props.url + '/backend/loadMemById.php', {
			params: {
				vk_id: this.props.user.id,
				sort_memes: this.state.sortMemes,
				category: this.state.category,
				id_mem: this.state.idMem,
				search: this.props.search
			}
		}).then(function (response) {
			self.setState({
				photo: response.data.file,
				likes: response.data.likes,
				dislikes: response.data.dislikes,
				user_id: response.data.user_id,
				countComments: response.data.comments,
				activeLike: response.data.activeLike,
				activeDislike: response.data.activeDislike,
				nextButton: response.data.next_button,
				prevButton: response.data.prev_button,
				outside: response.data.outside,
				outsideLink: response.data.outside_link,
				attachment: response.data.attachment,
				loadedMem: true
			});
			self.loadAvatar();
		});
		//подгрузка комментов
		setTimeout(function(){
			self.loadComments();
		}, 100);
	}
	
	//отправляем коммент 
	sendComment = () => {
		if(this.state.newComment !== '' && this.props.user.id && this.state.idMem !== ''){
			axios.get(this.props.url + '/backend/sendComment.php', {
				params: {
					vk_id: this.props.user.id,
					id: this.state.idMem,
					comment: this.state.newComment,
					search: this.props.search
				}
			});
			this.setState({newComment: ''});
			this.updateComments();
		}
	}
	
	//поделиться на стену
	toWall = () => {
		if(this.state.photo){
			var attachment = this.props.urlImages + this.state.photo;
			
			//var attachment;
			//if(this.state.outside === '1') attachment = 'photo-177029891_' + this.state.attachment;
			//else attachment = this.props.urlImages + this.state.photo;
			
			connect.send("VKWebAppShowWallPostBox", {
				"message": "#Мемошная - добавляйте свои мемы тут и голосуйте за другие - https://vk.com/app6827346",
				"attachments": attachment
			});
		}
	}
	
	//меняем переменную из input
	changeText = event => {
		this.setState({newComment: event.target.value});
	}
	
	//загрузка нового мема
	loadMem = (value) => {
		var self = this;
		axios.get(this.props.url + '/backend/loadNextMem.php', {
			params: {
				vk_id: this.props.user.id,
				sort_memes: this.state.sortMemes,
				category: this.state.category,
				id_mem: this.state.idMem,
				direction: value,
				search: this.props.search
			}
		}).then(function (response) {
			self.setState({
				photo: response.data.file,
				likes: response.data.likes,
				dislikes: response.data.dislikes,
				user_id: response.data.user_id,
				countComments: response.data.comments,
				activeLike: response.data.activeLike,
				activeDislike: response.data.activeDislike,
				idMem: response.data.id,
				outside: response.data.outside,
				outsideLink: response.data.outside_link,
				nextButton: response.data.next_button,
				prevButton: response.data.prev_button,
				attachment: response.data.attachment,
				loadedMem: true
			});
			self.loadAvatar();
			self.loadComments();
		});
	}
	
	//подгрузка данных владельца мема
	loadAvatar = () => {
		var self = this;
		axios.get(this.props.url + '/backend/loadUserData.php', {
			params: {
				user_id: this.state.user_id,
				search: this.props.search
			}
		}).then(function (response) {
			self.setState({
				nickname: response.data.nickname,
				name: response.data.name,
				avatar: response.data.photo,
				use_nickname: response.data.use_nickname,
				loadAuthor: true
			});
			if(response.data.use_nickname === '1') self.setState({authorName: response.data.nickname});
			else self.setState({authorName: response.data.name});
		});
	}
	
	//отправка лайка-дизлайка на сервер
	sendOpinion = (value) => {
		var opinion;
		if(value === 1) opinion = '1';
		axios.get(this.props.url + '/backend/addOpinion.php', {
			params: {
				vk_id: this.props.user.id,
				meme_id: this.state.idMem,
				like: opinion,
				search: this.props.search
			}
		});
	}
	
	//следующий мем
	nextMem = () => {
		this.setState({
			loadComments: false,
			loadAuthor: false,
			loadedMem: false,
			wallButton: false
		});
		this.loadMem('next');
		this.props.resetStory();
	}
	
	//предыдущий мем
	prevMem = () => {
		this.setState({
			loadComments: false,
			loadAuthor: false,
			loadedMem: false,
			wallButton: false
		});
		this.loadMem('prev');
		this.props.resetStory();
	}
	
	//подшрузка комментариев
	loadComments = () => {
		var self = this;
		axios.get(this.props.url + '/backend/loadComments.php', {
			params: {
				id_mem: this.state.idMem,
				search: this.props.search
			}
		}).then(function (response) {
			self.setState({
				comments: response.data,
				loadComments: true
			});
		});
	}
	
	//ставим лайк
	addLike = () => {
		var likes;
		if(this.state.activeLike){
			likes = Number(this.state.likes) - 1;
			this.setState({activeLike: false, likes: likes});
		}
		else {
			if(this.state.activeDislike) var dislikes = Number(this.state.dislikes) - 1;
			else dislikes = this.state.dislikes;
			likes = Number(this.state.likes) + 1;
			this.setState({
				activeLike: true,
				activeDislike: false,
				likes: likes,
				dislikes: dislikes
			});
		}
		var self = this;
		setTimeout(function(){
			self.sendOpinion();
		}, 100);
	}
	
	//ставим дизлайк
	addDislike = () => {
		var dislikes;
		if(this.state.activeDislike){
			dislikes = Number(this.state.dislikes) - 1;
			this.setState({activeDislike: false, dislikes: dislikes});
		}
		else {
			if(this.state.activeLike) var likes = Number(this.state.likes) - 1;
			else likes = this.state.likes;
			dislikes = Number(this.state.dislikes) + 1;
			this.setState({
				activeLike: false,
				activeDislike: true,
				likes: likes,
				dislikes: dislikes
			});
		}
		var self = this;
		setTimeout(function(){
			self.sendOpinion(1);
		}, 100);
	}
	
	//обновляем комменты
	updateComments = () => {
		this.setState({loadComments: false});
		var self = this;
		setTimeout(function(){
			self.loadComments();
		}, 300);
	}
	
	render() {
		if(!this.props.idMem) return <Redirect to="/catalog" />;
		
		let leftButton, rightButton, activeLike, activeDislike, comments, storyButton, wallButton, linkPic;
		if(this.state.loadComments) comments = <Comments comments={this.state.comments} />;
		
		//подсветка лайков - дизлайков
		if(this.state.activeLike) activeLike = 'count-likes active-like';
		else activeLike = 'count-likes';
		if(this.state.activeDislike) activeDislike = 'count-dislikes active-dislike';
		else activeDislike = 'count-dislikes';
		
		//активность кнопок
		if(this.state.nextButton) rightButton = <div className="right-button" onClick={this.nextMem}></div>;
		if(this.state.prevButton) leftButton = <div className="left-button" onClick={this.prevMem}></div>;
		
		//шапка
		let header
		if(this.state.loadAuthor) header = <Header 
					title={this.state.title}
					toBack={true}
					user_id={this.state.user_id}				
					loadAuthor={this.state.loadAuthor}
					authorName={this.state.authorName}
					photo={this.state.avatar}
					resetStory={this.props.resetStory}
					/>;
		else header = <Header 
					title={this.state.title}
					toBack={true}
					resetStory={this.props.resetStory}
					/>;
		
		//содержимое мема
		let memContent, footer;
		if(!this.state.loadedMem){
			memContent = <div className="load-mems"></div>;
			//нижний блок
			footer = <div>
						<div className="comment-footer-space"></div>
						<div className="comment-block">
							<div className="comment-block-buttons">
								<div className="to-wall"><div>На стену</div></div>
								<div className="to-history"><div>В историю</div></div>
							</div>
							<div className="comment-block-input">
								<input type="text" className="comment-input" placeholder="Твой комментарий" value={this.state.newComment} onChange={this.changeText} />
								<div className="send-comment"></div>
							</div>
						</div>
					</div>;
		}
		else{
			//проверяем ссылку картинки на внешний источник
			if(this.state.outside === '1') linkPic = this.state.outsideLink;
			else linkPic = this.props.urlImages + this.state.photo;
			
			memContent = <div>
				<div className="mem-image">
					<img src={linkPic} width="100%" alt="мемошная" />
				</div>
				<div className="mem-info">
					<div className="count-comments">{this.state.countComments}</div>
					<div className={activeLike} onClick={this.addLike}>{this.state.likes}</div>
					<div className={activeDislike} onClick={this.addDislike}>{this.state.dislikes}</div>
				</div>
				{comments}
				<Yandex />
			</div>;
			
			//кнопка добавить в историю
			if (this.props.doneStory) storyButton = <div className="to-history-done"><div>Добавлено</div></div>;
			else storyButton = <div className="to-history" onClick={() => this.props.getToken(this.state.photo)}><div>В историю</div></div>;
			
			//кнопка добавить на стену
			if (this.state.wallButton) wallButton = <div className="to-wall-done"><div>Добавлено</div></div>;
			else wallButton = <div className="to-wall" onClick={this.toWall}><div>На стену</div></div>;
			
			//нижний блок
			footer = <div onClick={this.props.test}>
						<div className="comment-footer-space"></div>
						<div className="comment-block">
							<div className="comment-block-buttons">
								{wallButton}
								{storyButton}
							</div>
							<div className="comment-block-input">
								<input type="text" className="comment-input" placeholder="Твой комментарий" value={this.state.newComment} onChange={this.changeText} />
								<div className="send-comment" onClick={this.sendComment}></div>
							</div>
						</div>
					</div>;
		}
		
		return (
			<div>
				{header}
				{memContent}
				{footer}
				{leftButton}
				{rightButton}
			</div>
		);
	}
}
export default Mem;