import React from 'react';
import axios from 'axios';

class Profile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: null,
			changeName: false,
			nickname: null,
			load: false,
			checkboxNick: null
		};
	}
	
	componentDidMount() {
		var self = this;
		axios.get(this.props.url + '/backend/getUserData.php', {
			params: {
				vk_id: this.props.id,
				search: this.props.search
			}
		}).then(function (response) {
			self.setState({
				nickname: response.data.nickname,
				first_name: response.data.name,
				last_name: response.data.last_name,
				photo: response.data.photo,
				use_nickname: response.data.photo,
				load: true
			});
			//проверяем что используется, никнейм или имя
			if(response.data.use_nickname === '1') self.setState({name: response.data.nickname, checkboxNick: true});
			else self.setState({name: response.data.name + " " + response.data.last_name, checkboxNick: false});
		});
	}

	//Кнопка "изменить"
	changeName = () => {
		this.setState({changeName: !this.state.changeName});
	}
	
	//Функция изминения переменной никнейма
	onChangeName = event => {
		this.setState({nickname: event.target.value});
	}
	
	//Изменение чекбокса
	onChangeCheckbox = event => {
		this.setState({checkboxNick: event.target.checked});
	}
	
	//Сохранение никнейма
	saveNickname = () => {
		if(this.state.checkboxNick){
			var checked = '1';
			this.setState({name: this.state.nickname});
		} 
		else{
			this.setState({name: this.state.first_name + " " + this.state.last_name});
		}
		axios.get(this.props.url + '/backend/saveNickname.php', {
			params: {
				vk_id: this.props.id,
				nickname: this.state.nickname,
				use_nickname: checked,
				search: this.props.search
			}
		});
		if(this.state.checkboxNick && this.state.nickname === '') this.setState({name: this.state.first_name + " " + this.state.last_name});
		this.setState({changeName: false});
	}
	
	render() {
		if(!this.state.load) return <div></div>;
		
		let changeName;
		//блок изменения никнейма
		if(!this.state.changeName) changeName = <div>
			<div className="user-name">
				<p>{this.state.name}</p>
			</div>
			<div className="change-name-block">
				<div className="change-name-button" onClick={this.changeName}>Изменить</div>
			</div>
		</div>;
		else changeName = <div className="change-name">
			<div>
				<label>
					<input className="checkbox" type="checkbox" onChange={this.onChangeCheckbox} defaultChecked={this.state.checkboxNick} />
					<span className="checkbox-custom"></span>
					<span className="label"> Использовать вместо имени</span>
				</label>
			</div>
			<div>
				<input type="text" placeholder="Введите никнейм" value={this.state.nickname} onChange={this.onChangeName} />
			</div>
			<div>
				<div className="save-nick" onClick={this.saveNickname}>Сохранить</div>
				<div className="cancel-nick" onClick={this.changeName}>Назад</div>
			</div>
		</div>;
		
		return (
			<div className="profile-block">
				<div className="profile-header"></div>
				<div className="avatar-block">
					<div className="avatar" style={{background: "url("+ this.state.photo +")no-repeat", backgroundSize: "cover"}}></div>
				</div>
				{changeName}
			</div>
		);
	}
}
export default Profile;