import React from 'react';
import { Redirect } from "react-router-dom";

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			redirect: false,
			authorName: this.props.authorName,
			photo: this.props.photo,
		};
	}
	
	//редиректим назад
	toBack = () => {
		this.setState({redirect: true});
		this.props.resetStory();
	}
	
	render() {
		let title, toBack, avatar;
		if(this.props.title) title = <div className="title">{this.props.title}</div>
		if(this.props.toBack) toBack = <div className="to-back" onClick={this.toBack}></div>
		if(this.state.redirect) return <Redirect to="/catalog" />
		if(this.props.loadAuthor) avatar = <div className="author">
			<img src={this.props.photo} alt="Автор" />
			<div className="author-name">{this.props.authorName}</div>
		</div>;
		
		return (
			<div>
				<div className="header-space"></div>
				<div className="header">
					{toBack}
					{title}
					{avatar}
				</div>
			</div>
		);
	}
}
export default Header;