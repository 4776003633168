import React from 'react';
import { Route, Switch } from "react-router-dom";
import connect from '@vkontakte/vkui-connect';
import './style.css';
import Intro from './components/Intro';
import Catalog from './components/Catalog';
import Mem from './components/Mem';
import { YMInitializer } from 'react-yandex-metrika';
import axios from 'axios';

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fetchedUser: null,
			idMem: false,
			load: null,
			token: false,
			doneStory: false,
			photo: null,
			sortMemes: 'day',
			category: 'best',
			url: 'https://memfront.vk-irs.ru/',
			urlImages: 'https://memback.vk-irs.ru',
			appId: 6827346,
			search: null
		};
	}

	componentDidMount() {
		connect.subscribe((e) => {
			switch (e.detail.type) {
				case 'VKWebAppGetUserInfoResult':
					this.setState({
						fetchedUser: e.detail.data,
						load: true,
						search: window.location.search
					});
				break;
				case 'VKWebAppAccessTokenReceived':
					this.setState({token: e.detail.data.access_token});
					var self = this;
					setTimeout(function(){
						self.toStory();
						self.changeButtonStory();
					}, 100);
				break;
				case 'VKWebAppAccessTokenFailed':
					console.log(e.detail.data.error_type);
				break;
				case 'VKWebAppCallAPIMethodResult':
					this.setState({uploadServer: e.detail.data.response.upload_url});
					this.toUploadServer();
				break;
				case 'VKWebAppCallAPIMethodFailed':
					console.log(e.detail.data);
				break;
				default: 
					console.log(e.detail.type);
				break;
			}
		});
		connect.send('VKWebAppGetUserInfo', {});
		connect.send("VKWebAppSetViewSettings", {"status_bar_style": "light", "action_bar_color": "#4B28D7"});
	}

	//изминение id мема
	editIdMem = (id) => { this.setState({idMem: id}); }
	
	//изминение сортировки 
	sortEdit = (sort) => { this.setState({sortMemes: sort}); }
	
	//изминение категории
	categoryEdit = (category) => { this.setState({category: category}); }
	
	//токен
	getToken = (value) => {
		connect.send("VKWebAppGetAuthToken",{"app_id": this.state.appId, "scope": "stories"});
		//меняет картинку, для сторис
		this.setState({photo: value});
	}
	
	//добавление в сторис
	toStory = () => {
		if(this.state.token && this.state.fetchedUser.id){
			connect.send("VKWebAppCallAPIMethod", {"method": "stories.getPhotoUploadServer", "params": {
					"v": "5.92",
					"access_token": this.state.token,
					"link_url": "https://vk.com/app" + this.state.appId,
					"add_to_news": 1
				}
			});
		}
	}
	
	//закидываем в сторис картинку
	toUploadServer = () => {
		const data = new FormData();
		data.append('photo', this.state.urlImages + this.state.photo);
		data.append('url', this.state.uploadServer);
		data.append('search', this.state.search);
		axios.post(this.state.url + "/backend/stories.php", data);
	}
	
	//меняет переменную для кнопки "В историю"
	changeButtonStory = () => { this.setState({doneStory: true}); }
	resetStory = () => { this.setState({doneStory: false}); }
	
	render() {
		//пока не загрузится
		if(!this.state.load) return <div className="load"><YMInitializer accounts={[52591939]} options={{webvisor: true}} version="2" /></div>;
		
		return (
			<div>
				<Switch location={this.props.location}>
					<Route exact path="/intro" render={props => <Intro {...props} />} />
					<Route exact path="/catalog" render={props => <Catalog {...props}
						user={this.state.fetchedUser}
						url={this.state.url}
						category={this.state.category}
						sortMemes={this.state.sortMemes}
						editIdMem={this.editIdMem}
						sortEdit={this.sortEdit}
						categoryEdit={this.categoryEdit}
						urlImages={this.state.urlImages}
						group={this.state.group}
						search={this.state.search}
					/>} />
					<Route exact path="/" render={props => <Mem {...props}
						idMem={this.state.idMem}
						user={this.state.fetchedUser}
						url={this.state.url}
						category={this.state.category}
						sortMemes={this.state.sortMemes}
						urlImages={this.state.urlImages}
						doneStory={this.state.doneStory}
						resetStory={this.resetStory}
						getToken={this.getToken}
						search={this.state.search}
					/>} />
				</Switch>
				<YMInitializer accounts={[52591939]} options={{webvisor: true}} version="2" />
			</div>
		);
	}
}

export default App;